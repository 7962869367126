/* :root {
  --shiki-color-text: theme('colors.white');
  --shiki-token-constant: theme('colors.emerald.300');
  --shiki-token-string: theme('colors.emerald.300');
  --shiki-token-comment: theme('colors.zinc.500');
  --shiki-token-keyword: theme('colors.sky.300');
  --shiki-token-parameter: theme('colors.pink.300');
  --shiki-token-function: theme('colors.violet.300');
  --shiki-token-string-expression: theme('colors.emerald.300');
  --shiki-token-punctuation: theme('colors.zinc.200');
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-white antialiased dark:bg-zinc-900;
  margin: 0;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.4rem; /* Ancho del scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #ffffff1a; /* Color de fondo del track del scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffff1a; /* Color del thumb del scrollbar */
  border-radius: 1rem; /* Borde redondeado del thumb del scrollbar */
}

@media (min-width: 768px){
  .dark .dark\:md\:bg-vert-dark-gradient {
    @apply bg-gradient-to-b from-zinc-900/0 via-zinc-900/100 to-zinc-900/100
  }

  .md\:bg-vert-light-gradient {
    @apply bg-gradient-to-b from-white/0 via-white/100 to-white/100
  }

  .dark .dark\:md\:bg-vert-dark-gradient-inverse {
    @apply bg-gradient-to-t from-zinc-900/0 via-zinc-900/100 to-zinc-900/100
  }

  .md\:bg-vert-light-gradient-inverse {
    @apply bg-gradient-to-t from-white/0 via-white/100 to-white/100
  }
}


.overlay {
  background: rgba(0, 0, 0, 0.8);
  height: 10vh; }

.spinner {
  font-size: 1.2rem;
  position: relative;
  width: 1em;
  height: 1em; }
  .spinner.center {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* margin: auto;  */
  }
  .spinner .spinner-blade {
    position: absolute;
    left: .4629em;
    bottom: 0;
    width: .074em;
    height: .2777em;
    border-radius: .0555em;
    background-color: transparent;
    transform-origin: center -.2222em;
    animation: spinner-fade 1s infinite linear; 
  }
    .spinner .spinner-blade:nth-child(1) {
      animation-delay: 0s;
      transform: rotate(0deg); }
    .spinner .spinner-blade:nth-child(2) {
      animation-delay: 0.083s;
      transform: rotate(30deg); }
    .spinner .spinner-blade:nth-child(3) {
      animation-delay: 0.166s;
      transform: rotate(60deg); }
    .spinner .spinner-blade:nth-child(4) {
      animation-delay: 0.249s;
      transform: rotate(90deg); }
    .spinner .spinner-blade:nth-child(5) {
      animation-delay: 0.332s;
      transform: rotate(120deg); }
    .spinner .spinner-blade:nth-child(6) {
      animation-delay: 0.415s;
      transform: rotate(150deg); }
    .spinner .spinner-blade:nth-child(7) {
      animation-delay: 0.498s;
      transform: rotate(180deg); }
    .spinner .spinner-blade:nth-child(8) {
      animation-delay: 0.581s;
      transform: rotate(210deg); }
    .spinner .spinner-blade:nth-child(9) {
      animation-delay: 0.664s;
      transform: rotate(240deg); }
    .spinner .spinner-blade:nth-child(10) {
      animation-delay: 0.747s;
      transform: rotate(270deg); }
    .spinner .spinner-blade:nth-child(11) {
      animation-delay: 0.83s;
      transform: rotate(300deg); }
    .spinner .spinner-blade:nth-child(12) {
      animation-delay: 0.913s;
      transform: rotate(330deg); 
    }

@keyframes spinner-fade {
  0% {
    background-color: #69717d; 
  }
  100% {
    background-color: transparent; 
  }
}